import { defineStore } from 'pinia'

import { checkUserAccess, getLogout } from '@/api/auth-routes'
import { removeAccessToken } from '@/helpers/auth-helper'

import { useDefaultStore } from '@/store/defaultStore'

export const useAuthorizationStore = defineStore('authorizationStore', {
    state: () => ({
        user: null,
        permissions: {},
    }),

    actions: {
        saveUserData({ user, permissions }) {
            this.user = user
            this.permissions = permissions
        },

        checkPermission(field) {
            return this.permissions ? !!this.permissions[field] : false
        },

        async getCheckUserAccess() {
            const { success, payload, message } = await checkUserAccess()

            if (success) {
                this.saveUserData({ user: payload.user, permissions: payload.permissions })
            } else {
                this.$reset()
                removeAccessToken()
                const defaultStore = useDefaultStore()
                defaultStore.setErrorMessage({ message })
            }
        },

        async logout() {
            const defaultStore = useDefaultStore()

            const { success, message } = await getLogout()

            if (success) {
                defaultStore.setSuccessMessage({ message })
                this.$reset()
                removeAccessToken()
            } else {
                defaultStore.setErrorMessage({ message })
            }
        },
    },
})
